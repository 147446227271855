import * as React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import Seo from '../components/seo';

import Hero from '../components/top/hero';
import Kaminashi from '../components/top/kaminashi';
import About from '../components/top/about';
import PhotoGallery from '../components/top/photo_gallery';
import Member from '../components/top/member';
import Event from '../components/top/event';
import Contents from '../components/top/pu_contents';
import Recruit from '../components/top/recruit';
import Faq from '../components/top/faq';

const IndexPage = ({ location, data }) => (
  <Layout location={location.pathname}>
    <Seo title="採用情報" url={location.origin} />
    <div className="paper_bg">
      <Hero />
      <Kaminashi />
    </div>
    <About />
    <PhotoGallery list={data.allMicrocmsPhotoGallery.edges} />
    <Member list={data.allMicrocmsMember.edges} />
    <Contents
      // note={data.feednoteRss}
      hatena={data.feedhatenaRss}
      podcast={data.feedpodcastRss}
      spotify={data.feedspotifyRss}
    />
    <Event list={data.allMicrocmsEvent.edges} />
    <Recruit list={data.allMicrocmsRecruit.edges} />
    <Faq
      list={data.allMicrocmsFaq.edges}
      categories={data.allMicrocmsFaqCategories.edges}
    />
  </Layout>
);

export default IndexPage;

export const query = graphql`
  query {
    allMicrocmsEvent(limit: 3, sort: { fields: [sortIndex], order: ASC }) {
      edges {
        node {
          eventId
          link
          thumbnail {
            url
          }
          title
          eventDate
          eventTime
          publishedAt
        }
      }
    }

    allMicrocmsFaq(sort: { fields: [sortIndex], order: ASC }) {
      edges {
        node {
          faqId
          question
          answer
          category {
            category
            id
          }
        }
      }
    }

    allMicrocmsFaqCategories(sort: { fields: [sortIndex], order: ASC }) {
      edges {
        node {
          faqCategoriesId
          category
        }
      }
    }

    allMicrocmsPhotoGallery(sort: { fields: [sortIndex], order: ASC }) {
      edges {
        node {
          photoGalleryId
          sortIndex
          thumbnail {
            url
          }
          caption
          publishedAt
        }
      }
    }

    allMicrocmsRecruit(sort: { fields: [sortIndex], order: ASC }) {
      edges {
        node {
          recruitId
          sortIndex
          title
          link
        }
      }
    }

    allMicrocmsMember(sort: { fields: [sortIndex], order: ASC }) {
      edges {
        node {
          memberId
          sortIndex
          thumbnail {
            url
          }
          firstName
          secondName
          job
          department
          isDisplayTopPage
          twitterUrl
          noteUrl
          wantedlyUrl
          githubUrl
          publishedAt
        }
      }
    }

    feedhatenaRss {
      content
      id
      link
      title
      pubDate
    }

    feedpodcastRss {
      link
      itunes {
        image
      }
      id
      title
      pubDate
    }

    feedspotifyRss {
      link
      itunes {
        image
      }
      id
      title
      pubDate
    }

  }
`;
