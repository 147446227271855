import React from 'react';

import PickupCard from '../pickup_card';
import SectionTitle from '../section-title';
import AnimationWrapper from '../animation-wrapper';

import * as Styles from '../../styles/top/_pu_contents.module.scss';

const Contents = ({
  hatena, podcast, spotify,
}) => {
  let hatenaThumbnails;
  if (hatena.content.match(/<img[^>]+>/gi)) {
    hatenaThumbnails = hatena.content.match(/<img[^>]+>/gi);
  }

  const getHatenaThumbnail = () => {
    if (hatenaThumbnails[0]) {
      return hatenaThumbnails[0].match(/src=["|'](.*?)["|']/)[1];
    }

    return null;
  };

  return (
    <section className={Styles.contents}>
      <div className="container">
        <AnimationWrapper>
          <SectionTitle white>PICKUP CONTENTS</SectionTitle>
          <h2 className="section-title-ja wh">ピックアップコンテンツ</h2>
        </AnimationWrapper>
        <div className="row">
          <div className="col col_4">
            <AnimationWrapper>
              <PickupCard
                header="コーポレートムービー"
                body="現場ドリブンで課題に寄り添い挑戦する、カミナシの今と未来"
                link="https://www.youtube.com/watch?v=iv9WJLVt77g"
                postLink="https://www.youtube.com/watch?v=iv9WJLVt77g"
              >
                <iframe
                  src="https://www.youtube.com/embed/iv9WJLVt77g"
                  width="100%"
                  title="kaminashi_corp_movie"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                />
              </PickupCard>
            </AnimationWrapper>
          </div>

          <div className="col col_4">
            <AnimationWrapper>
              <PickupCard
                header="カミナシSaaS FM"
                body={podcast.title}
                link="https://anchor.fm/kaminashi"
                postLink={podcast.link}
              >
                <img
                  src={podcast.itunes.image}
                  alt="カミナシSaaS FM"
                  width={626}
                  height={332}
                />
              </PickupCard>
            </AnimationWrapper>
          </div>

          <div className="col col_4">
            <AnimationWrapper>
              <PickupCard
                header="カミナシ 全開オープンRadio"
                body={spotify.title}
                link="https://anchor.fm/kaminashi-radio"
                postLink={spotify.link}
              >
                <img
                  src={spotify.itunes.image}
                  alt="カミナシ 全開オープンRadio"
                  width={626}
                  height={332}
                />
              </PickupCard>
            </AnimationWrapper>
          </div>
        </div>

        <div className="row center">
          {/* <div className="col col_4">
            <AnimationWrapper>
              <PickupCard
                header="カミナシnote編集部"
                body={note.title}
                link="https://note.kaminashi.jp/"
                postLink={note.link}
              >
                <img
                  src={note.media.thumbnail}
                  alt="カミナシnote編集部"
                  width={626}
                  height={332}
                />
              </PickupCard>
            </AnimationWrapper>
          </div> */}

          <div className="col col_4">
            <AnimationWrapper>
              <PickupCard
                header="カミナシ エンジニアブログ"
                body={hatena.title}
                link="https://kaminashi-developer.hatenablog.jp/"
                postLink={hatena.link}
              >
                {getHatenaThumbnail() && (
                  <img
                    src={getHatenaThumbnail()}
                    alt="カミナシ エンジニアブログ"
                    width={626}
                    height={332}
                  />
                )}
              </PickupCard>
            </AnimationWrapper>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contents;
